import React from 'react';
import './App.css';
import SearchPage from "./feature/search/component/SearchPage";

function App() {
  return (
    <div className="App">
        <SearchPage/>
    </div>
  );
}

export default App;
