import React from 'react';
import { Input, Row, Col, Card, Pagination } from 'antd';

const SearchPage: React.FC = () => {
    const handleSearch = (value: string) => {
        console.log(value);
        // Here you can handle the search logic
    };

    // Dummy data for search results
    const searchResults = Array(10).fill({
        title: 'Search Result',
        description: 'This is a search result.',
    });

    return (
        <div>
            <Input.Search
                placeholder="input search text"
                onSearch={handleSearch}
                enterButton
                size="large"
                style={{ padding: 8, width: 800, marginTop: '20px' }}
            />
            <Row gutter={16} style={{ marginTop: '20px' }}>
                {searchResults.map((result, index) => (
                    <Col key={index} style={{ width: 300, marginTop: '20px' }}>
                        <Card title={result.title} style={{ width: '100%', height: 300 }}>
                            {result.description}
                        </Card>
                    </Col>
                ))}
            </Row>
            <Pagination defaultCurrent={1} total={50} style={{ marginTop: '20px' }} />
        </div>
    );
};

export default SearchPage;